import React from 'react';
import PropTypes from 'prop-types';
import { ArticleInner } from './ArticleInner';
import { Transporter } from '../../components/Transporter';

export const ArticleWrapper = ({ content }) => (
  <>
    <ArticleInner content={content} isTransported={false} key="ArticleInner" />
    <Transporter content={content} key="Transporter" />
  </>
);

ArticleWrapper.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
